import { VStack, HStack, Skeleton } from "@chakra-ui/react";
import MotionBox from "~/components/MotionBox";
import { Mobile, Desktop } from "~/components/MediaQuery";

export default function ShopLoadingPlaceholder() {
  return (
    <MotionBox
      w="100%"
      h="100%"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <Desktop>
        <VStack w="100%" h="100%" spacing="8" p="8">
          <HStack flex="1" w="100%" spacing="8">
            <Skeleton flex="1" h="100%" borderRadius="brand" />
            <Skeleton flex="1" h="100%" borderRadius="brand" />
            <Skeleton flex="2" h="100%" borderRadius="brand" />
          </HStack>
          <HStack flex="1" w="100%" spacing="8">
            <Skeleton flex="2" h="100%" borderRadius="brand" />
            <Skeleton flex="1" h="100%" borderRadius="brand" />
            <Skeleton flex="2" h="100%" borderRadius="brand" />
          </HStack>
          <HStack flex="1" w="100%" spacing="8">
            <Skeleton flex="1" h="100%" borderRadius="brand" />
            <Skeleton flex="2" h="100%" borderRadius="brand" />
            <Skeleton flex="2" h="100%" borderRadius="brand" />
          </HStack>
        </VStack>
      </Desktop>
      <Mobile>
        <VStack w="100%" h="100%" spacing="4" p="2">
          <Skeleton w="100%" h="36" />
          <Skeleton w="100%" h="48" />
          <Skeleton w="100%" h="56" />
          <Skeleton w="100%" h="32" />
          <Skeleton w="100%" h="40" />
        </VStack>
      </Mobile>
    </MotionBox>
  );
}

import { useState, useEffect, createContext, useContext } from "react";
import useDebounce from "~/context/UseDebounce";
import firebase from "../firebase/clientApp";
import * as Sentry from "@sentry/nextjs";

const MESSAGES_UPDATE_DELAY = 100;

const DEFAULT_MESSAGE = (message) => {
  return {
    senderType: "bot",
    messageType: "text",
    payload: {
      text: message,
    },
  };
};

export const ShopChatContext = createContext();

export default function ShopChatContextComponent({ shop, userId, children }) {
  // Messages are updated with debounce. This is to prevent a flood of new messages from collapsing the UI rendering.
  // Basically, after every new message there is a small delay before rendering them. If many messages arrive in a short amount of time
  // (which usually happens when firebase returns the already stored previous messages) the UI is not rendered aftear each one arrives, but is rendered all at
  // once after a short period of time
  const [messages, setMessages] = useState(initialMessages());
  const debouncedMessages = useDebounce(messages, MESSAGES_UPDATE_DELAY);
  const [typingUsers, setTypingUsers] = useState([]);

  function initialMessages() {
    if (shop.chat?.welcomeMessageEnabled)
      return [DEFAULT_MESSAGE(shop.chat.welcomeMessage.es)];
    else return [];
  }

  function addMessage(messageId, message) {
    const newMessage = { id: messageId, ...message };
    setMessages((actualMessages) => actualMessages.concat([newMessage]));
  }

  function updateMessage(messageId, message) {
    const updatedMessage = { id: messageId, ...message };
    setMessages((actualMessages) => {
      return actualMessages.map((message) => {
        if (message.id === updatedMessage.id) return updatedMessage;
        else return message;
      });
    });
  }

  function removeMessage(messageId) {
    setMessages((actualMessages) =>
      actualMessages.filter((message) => message.id !== messageId)
    );
  }

  useEffect(() => {
    if (shop.id && userId) {
      const visitorChatDatabaseRef = firebase
        .database()
        .ref(`shops/${shop.id}/chats/${userId}/messages`)
        .orderByChild("createdAt");

      visitorChatDatabaseRef.on(
        "child_added",
        (data) => addMessage(data.key, data.val()),
        (error) => Sentry.captureException(error)
      );

      visitorChatDatabaseRef.on(
        "child_changed",
        (data) => updateMessage(data.key, data.val()),
        (error) => Sentry.captureException(error)
      );

      visitorChatDatabaseRef.on(
        "child_removed",
        (data) => removeMessage(data.key),
        (error) => Sentry.captureException(error)
      );

      // Unsubscribe listener on unmount
      return () => {
        setMessages([DEFAULT_MESSAGE]);
        visitorChatDatabaseRef.off();
      };
    }
  }, [shop.id, userId]);

  useEffect(() => {
    if (shop.id && userId) {
      const typingReference = firebase
        .database()
        .ref(`shops/${shop.id}/chats/${userId}/typing`);

      typingReference.on(
        "value",
        (snapshot) => {
          const value = Object.values(snapshot.val() || []);
          setTypingUsers(value);
        },
        (error) => Sentry.captureException(error)
      );

      return () => typingReference.off();
    }
  }, [shop.id, userId]);

  return (
    <ShopChatContext.Provider
      value={{ messages: debouncedMessages, typingUsers }}
    >
      {children}
    </ShopChatContext.Provider>
  );
}

// Custom hook that shorhands the context!
export const useShopChat = () => useContext(ShopChatContext);

import format from "date-fns/format";
import formatDistance from "date-fns/formatDistance";
import { es } from "date-fns/locale";
import {
  ANONYMOUS_USER_SHORT_ID_LENGTH,
  PRODUCT_SLUG_UID_LENGTH,
} from "~/utils/Constants";
import { v4 as uuidv4 } from "uuid";
var slugify = require("slugify");

// Converts a number that represents a price and formats it with points and commas according to the locale.
export function formatPrice(price) {
  return new Intl.NumberFormat("es").format(price);
}

// Recives a country ISO code (3 chars) and returns the country's ISO currency (3 chars)
export function countryIsoToCurrencyIso(countryIso) {
  switch (countryIso) {
    case "ARG": // Argentina
      return "ARS"; // Peso Argentino
    case "CHL": // Chile
      return "CLP"; // Peso Chileno
    case "URY": // Uruguay
      return "UYU"; // Peso Uruguayo
    case "COL": // Colombia
      return "COP"; // Peso Colombiano
    case "BRA": // Colombia
      return "BRL"; // Real
    case "MEX": // Colombia
      return "MXN"; // Peso Mexicano
    case "USA":
    default:
      return "USD";
  }
}

// Recives a country ISO code (3 chars) and returns the country's ISO currency (3 chars)
export function currencyIsoToCurrencyName(currencyIso) {
  switch (currencyIso) {
    case "ARS": // Peso Argentino
      return "Peso Argentino";
    case "CLP":
      return "Peso Chileno";
    case "UYU":
      return "Peso Uruguayo";
    case "COP": // Colombia
      return "Peso Colombiano"; // Peso Colombiano
    case "BRL": // Colombia
      return "Real Brasilero"; // Real
    case "MXN": // Colombia
      return "Peso Mexicano"; // Peso Mexicano
    case "USD":
    default:
      return "Dólar Americano";
  }
}

// Returns a promise for an array of provinces for a given country ISO
export async function provincesOfCountryIso(countryIso) {
  return fetch(`/regions/provinces_${countryIso}.json`).then((response) =>
    Promise.resolve(response.json())
  );
}

// Recivers a role as stored in the backend and adapts it to a user friendly name.
export function userRoleToString(role) {
  switch (role) {
    case "owner":
      return "Propietario";
    case "editor":
      return "Editor";
    case "viewer":
      return "Lector";
    default:
      return "Desconocido";
  }
}

export function timeInWords(timeInMilliseconds) {
  if (timeInMilliseconds)
    return formatDistance(new Date(timeInMilliseconds), new Date(), {
      addSuffix: true,
      locale: es,
    });
  else return "";
}

export function formatDateTime(timeInMilliseconds) {
  if (timeInMilliseconds)
    return format(new Date(timeInMilliseconds), "EEEE dd/MM/yyyy HH:mm'hs'", {
      locale: es,
    });
  else return "";
}

export function formatDate(timeInMilliseconds) {
  if (timeInMilliseconds)
    return format(new Date(timeInMilliseconds), "EEEE dd/MM/yyyy", {
      locale: es,
    });
  else return "";
}

export function visitorIdToShortId(visitorId) {
  return visitorId.slice(-ANONYMOUS_USER_SHORT_ID_LENGTH).toUpperCase();
}

function visitorIdToSingleDigitNumber(visitorId) {
  // Converts a string into a unique but deterministic number
  // Source: https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0
  const hashCode = (string) => {
    for (var i = 0, hash = 0; i < string.length; i++)
      hash = (Math.imul(31, hash) + string.charCodeAt(i)) | 0;
    return hash;
  };

  let hashedId = hashCode(visitorId);
  return Math.abs(hashedId % 10); //Take the last digit of the hash. Hash may be negative, so take the absoulte number.
}

// Use the visitor ID to deterministically return always the same type of avatar, so that users don't get confused.
export function visitorIdToGenericAvatarImageUrl(visitorId) {
  let avatarStyle = visitorIdToSingleDigitNumber(visitorId);

  return `/avatars/generic_avatar_${avatarStyle}.jpg`;
}

// Deterministically assings a color to a user based on its user ID
export function userIdToColor(userId) {
  let colors = [
    "#f44336", // Red
    "#e91e63", // Pink
    "#9c27b0", // Purple
    "#673ab7", // Deep purple
    "#00bcd4", // Cyan
    "#009688", // Teal
    "#4caf50", // Green
    "#8bc34a", // Light Green
    "#ffeb3b", // Yellow
    "#ff9800", // Orange
  ];

  let colorIndexForVisitior = visitorIdToSingleDigitNumber(userId);

  return colors[colorIndexForVisitior];
}

// Converts a string into a URL friendly string
// Source: https://github.com/simov/slugify
export function textToSlug(text) {
  return slugify(text, {
    replacement: "-", // replace spaces with replacement character, defaults to `-`
    remove: undefined, // remove characters that match regex, defaults to `undefined`
    lower: true, // convert to lower case, defaults to `false`
    strict: false, // strip special characters except replacement, defaults to `false`
    locale: "es", // language code of the locale to use
  });
}

export function generateItemSlug(itemName) {
  // Add a UID at the end of the product or collection name to avoid possible slug duplicates within the same shop
  let itemNameWithUid = `${itemName}-${uuidv4().slice(
    -PRODUCT_SLUG_UID_LENGTH
  )}`;

  return textToSlug(itemNameWithUid);
}

// Makes the first latter of a string uppercase
export const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || "";

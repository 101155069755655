import dynamic from "next/dynamic";
import { useEffect, useMemo, useState } from "react";
import firebase from "~/firebase/clientApp";
import ShopProvider from "~/context/ShopContext";
import ShopChatProvider from "~/context/ShopChatContext";
import CartProvider from "~/context/CartContext";
import VisitorActionsProvider from "~/context/VisitorActionsContext";
import { UserContext } from "~/context/userContext";
import { ShopContext } from "~/context/ShopContext";
import { useAnimation } from "framer-motion";
import { useRouter } from "next/router";
import { Box, VStack, LightMode, useToast } from "@chakra-ui/react";
import PageHead from "~/components/PageHead";
import MotionBox from "~/components/MotionBox";
import { Mobile, Desktop } from "~/components/MediaQuery";
import * as Sentry from "@sentry/nextjs";
import { useCart } from "~/context/CartContext";

// Eager loaded components
import ShopHeader from "~/components/shop/header/ShopHeader";
import ShopFooter from "~/components/shop/footer/ShopFooter";
import ShopMobileNavBar from "~/components/shop/ShopMobileNavBar";
import ShopCollectionView from "~/components/shop/ShopCollectionView";
import ShopProductView from "~/components/shop/ShopProductView";
import ShopLoadingPlaceholder from "~/components/shop/ShopLoadingPlaceholder";

// Lazy loaded components
const ShopAbout = dynamic(() => import("~/components/shop/ShopAbout"));
const ShopSearchResults = dynamic(() =>
  import("~/components/shop/ShopSearchResults")
);
const Checkout = dynamic(() => import("~/components/checkout/Checkout"));
const ShopChatBubble = dynamic(
  () => import("~/components/shop/ShopChatBubble"),
  { ssr: false }
);
const ShopChatFullscreen = dynamic(
  () => import("~/components/shop/ShopChatFullscreen"),
  { ssr: false }
);

const COLLECTION_SHOPS = "shops";
const COLLECTION_ITEMS = "items";

export default function ShopFrame({ initialShop }) {
  const router = useRouter();
  const shopDomain = router.query.shopDomain;
  const shopItemSlug = router.query.slug ? router.query.slug[0] : null;

  if (shopDomain)
    return (
      <ShopProvider initialShop={initialShop} domain={shopDomain}>
        <ShopContext.Consumer>
          {({ shop, loadingShop }) => {
            if (loadingShop) return <ShopLoadingPlaceholder />;

            if (shop)
              return (
                <UserContext.Consumer>
                  {({ user }) => {
                    if (user)
                      // Start tracking user presence as visitor and get ready for actions reporting
                      return (
                        <VisitorActionsProvider shopId={shop.id}>
                          <>
                            <PageHead title={shop.displayNames.es} />

                            <ShopChatProvider shop={shop} userId={user.uid}>
                              <CartProvider shopId={shop.id} userId={user.uid}>
                                {/* Light Theme is enforced in the shop. This only applies to components not styled by the shops own theme*/}
                                <LightMode>
                                  <MainContainer
                                    shop={shop}
                                    shopItemSlug={shopItemSlug}
                                  />
                                </LightMode>
                              </CartProvider>
                            </ShopChatProvider>
                          </>
                        </VisitorActionsProvider>
                      );

                    // Should never happen, but just in case for some reason not even an anonymous login is possible
                    return null;
                  }}
                </UserContext.Consumer>
              );

            // No shop found
            router.replace("/404");
            return null;
          }}
        </ShopContext.Consumer>
      </ShopProvider>
    );
  else return null;
}

function MainContainer({ shop, shopItemSlug }) {
  const { products } = useCart();

  // Performance optimzation:
  // We use `useCallback` here to memoize the function for subsequent renders.
  // A change in the 'shop' or 'shopItemSlug' props will trigger a re-render of the function
  const memoizedContent = useMemo(
    (props) => <PageContent shop={shop} shopItemSlug={shopItemSlug} />,
    [shop, shopItemSlug]
  );

  return (
    <VStack
      position="relative"
      w="100%"
      h="100%"
      minH="100vh"
      align="flex-start"
      backgroundColor={shop.theme.colors.background.superLite}
      overflowY="auto"
      overflowX="hidden"
      spacing="0"
      fontFamily={shop.theme.fonts.body}
    >
      <Box
        w="100%"
        position={{ base: undefined, md: "sticky" }}
        top="0"
        zIndex="docked"
        backgroundColor={shop.theme.colors.background.superLite}
        mb={{ base: "4", md: "8" }}
      >
        <ShopHeader products={products} />
      </Box>

      <Box flex="1" w="100%">
        {memoizedContent}
      </Box>

      <Box w="100%" pt={{ base: "0", md: "12" }}>
        <ShopFooter
          preferredStyle={shopItemSlug === "about" ? "simple" : null}
        />
      </Box>

      <Desktop>
        <Box position="fixed" bottom="0" right="0" pr="8" pb="8">
          <ShopChatBubble />
        </Box>
      </Desktop>

      {/* Bottom navbar only available on mobile layouts*/}
      {/* The follwing empty box acts as a padding for the NavBar below */}
      <Mobile>
        <Box w="100%" h="16" />
      </Mobile>
      {/* Note: Using 'fixed' instead of 'sticky' because Safari on iOS has a weird behavior otherwise */}
      <Mobile w="100%" position="fixed" bottom="0" left="0" right="0">
        <ShopMobileNavBar />
      </Mobile>
    </VStack>
  );
}

function PageContent(props) {
  const { shop, shopItemSlug } = props;
  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      opacity: [0, 1],
      x: [-15, 0],
      transition: { ease: "easeIn", duration: 0.2 },
    });
  }, [shopItemSlug]);

  const Content = ({ shopItemSlug }) => {
    switch (shopItemSlug) {
      // Handle checkout subpage
      case "checkout":
        return <Checkout />;

      // Handle About shop subpage
      case "about":
        return <ShopAbout />;

      // Handle shop's chat subpage
      case "chat":
        return <ShopChatFullscreen />;

      // Handle shop's chat subpage
      case "search":
        return <ShopSearchResults />;

      // Default to a Item View page
      default:
        return <ShopItemView shop={shop} shopItemSlug={shopItemSlug} />;
    }
  };

  // Performance optimzation:
  // We use `useCallback` here to memoize the function for subsequent renders.
  // A change in the 'section' prop will trigger a re-render of the function
  const memoizedContent = useMemo(
    (props) => <Content shopItemSlug={shopItemSlug} {...props} />,
    [shopItemSlug]
  );

  return (
    <MotionBox w="100%" h="100%" animate={controls}>
      {memoizedContent}
    </MotionBox>
  );
}

const ShopItemView = ({ shop, shopItemSlug }) => {
  const router = useRouter();
  const toast = useToast();
  const [shopItem, setShopItem] = useState(null);
  const [loadingShopItem, setLoadingShopItem] = useState(true);

  useEffect(() => {
    if (shopItemSlug) {
      const unsubscriber = firebase
        .firestore()
        .collection(COLLECTION_SHOPS)
        .doc(shop.id)
        .collection(COLLECTION_ITEMS)
        .where("slug", "==", shopItemSlug)
        .limit(1)
        .onSnapshot((querySnapshot) => {
          try {
            if (!querySnapshot.empty) {
              let doc = querySnapshot.docs[0];
              let shopItem = { id: doc.id, ...doc.data() };
              setShopItem(shopItem);
            }
          } catch (error) {
            handleError(error);
          } finally {
            setLoadingShopItem(false);
          }
        });

      // Unsubscribe listener on unmount
      return () => unsubscriber();
    }
    // Handle root collection case
    else {
      const unsubscriber = firebase
        .firestore()
        .collection(COLLECTION_SHOPS)
        .doc(shop.id)
        .collection(COLLECTION_ITEMS)
        .doc(shop.rootItem)
        .onSnapshot((collectionDoc) => {
          try {
            if (
              !collectionDoc ||
              !collectionDoc.exists ||
              collectionDoc.isEmpty
            ) {
              setShopItem(null);
            } else {
              let shopItem = { id: collectionDoc.id, ...collectionDoc.data() };
              setShopItem(shopItem);
            }
          } catch (error) {
            handleError(error);
          } finally {
            setLoadingShopItem(false);
          }
        });

      // Unsubscribe listener on unmount
      return () => unsubscriber();
    }
  }, [shop.id, shopItemSlug]);

  const handleError = (error) => {
    Sentry.captureException(error);
    toast({
      position: "top",
      title: "Ocurrió un error.",
      description: "No pudimos traer los datos de la tienda en este momento.",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  // Handle loading item case
  if (loadingShopItem) return <ShopLoadingPlaceholder />;

  // Handle no item found case
  if (!shopItem) {
    // No item found
    router.replace("/404");
    return null;
  }

  // Handle regular cases
  switch (shopItem.type) {
    case "product":
      return <ShopProductView product={shopItem} />;
    case "collection":
      return <ShopCollectionView collection={shopItem} />;
    default:
      return null;
  }
};

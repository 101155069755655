import { cloneElement } from "react";
import { VStack, HStack } from "@chakra-ui/react";
import Link from "next/link";
import { useShop } from "~/context/ShopContext";
import { useRouter } from "next/router";
import { Paragraph04 } from "~/components/Texts";
import MotionBox from "~/components/MotionBox";
import {
  FiGrid,
  FiMessageCircle,
  FiShoppingCart,
  FiInfo,
} from "react-icons/fi";

export default function ShopMobileNavBar() {
  const { shop } = useShop();
  const router = useRouter();
  const shopDomain = router.query.shopDomain;
  const slug = router.query.slug ? router.query.slug[0] : null;

  const getCurrentPage = () => {
    switch (slug) {
      case "about":
        return "about";
      case "checkout":
        return "checkout";
      case "chat":
        return "chat";
      default:
        return "shop";
    }
  };

  return (
    <HStack
      zIndex="docked"
      w="100%"
      px="4"
      py="2"
      justify="center"
      spacing="8"
      borderTopRadius={shop.theme.radius}
      backgroundColor={shop.theme.colors.background.lite}
      boxShadow="0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06)"
    >
      <HStack w="100%" maxW="400px" justifyContent="space-evenly">
        <Link href={`/${shopDomain}/`}>
          <a>
            <NavigationButton
              theme={shop.theme}
              icon={<FiGrid />}
              label="Tienda"
              isSelected={getCurrentPage() === "shop"}
            />
          </a>
        </Link>

        {shop.chat?.enabled && (
          <Link href={`/${shopDomain}/chat`}>
            <a>
              <NavigationButton
                theme={shop.theme}
                icon={<FiMessageCircle />}
                label="Mensajes"
                isSelected={getCurrentPage() === "chat"}
              />
            </a>
          </Link>
        )}

        <Link href={`/${shopDomain}/checkout`}>
          <a>
            <NavigationButton
              theme={shop.theme}
              icon={<FiShoppingCart />}
              label="Carrito"
              isSelected={getCurrentPage() === "checkout"}
            />
          </a>
        </Link>

        <Link href={`/${shopDomain}/about`}>
          <a>
            <NavigationButton
              theme={shop.theme}
              icon={<FiInfo />}
              label="Info"
              isSelected={getCurrentPage() === "about"}
            />
          </a>
        </Link>
      </HStack>
    </HStack>
  );
}

const NavigationButton = ({ theme, icon, label, isSelected }) => {
  return (
    <MotionBox
      whileHover={{
        color: isSelected
          ? theme.colors.action.primary
          : theme.colors.action.secondary,
        scale: [1, 1.025, 1],
      }}
      color={isSelected ? theme.colors.action.primary : theme.colors.text}
    >
      <VStack spacing="1" cursor="pointer">
        {cloneElement(icon, {
          size: "20",
        })}
        <Paragraph04 fontSize="0.7rem">
          <b>{label}</b>
        </Paragraph04>
      </VStack>
    </MotionBox>
  );
};

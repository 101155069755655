import PropTypes from "prop-types";
import { useState, useEffect, createContext, useContext } from "react";
import {
  startTrackingUserPresenceAsVisitor,
  trackUserActionAsVisitor,
} from "~/actions/UserActions";
import * as Sentry from "@sentry/nextjs";

export const VisitorActionsContext = createContext();

VisitorActionsContextComp.propTypes = {
  shopId: PropTypes.string.isRequired,
};

export default function VisitorActionsContextComp({ shopId, children }) {
  const [connectionId, setConnectionId] = useState(null);
  const [pendingTrackingRequests, setPendingTrackingRequests] = useState([]);

  // Establish a presence connection.
  useEffect(() => {
    startTrackingUserPresenceAsVisitor(shopId).then((newConnectionId) => {
      setConnectionId(newConnectionId);
      Sentry.setContext("firebaseRealtimeDbConnectionId", newConnectionId);
    });
  }, []);

  // Process enqueued tracking requests whenever there is a new presence connection available.
  useEffect(() => {
    if (connectionId) {
      pendingTrackingRequests.forEach((pendingRequest) =>
        trackUserActionAsVisitor(
          pendingRequest.shopId,
          connectionId,
          pendingRequest.action
        )
      );
      setPendingTrackingRequests([]);
    }
  }, [connectionId]);

  // Exposes a function for tracking visitor actions
  const trackVisitorAction = (action) => {
    if (shopId) {
      // It's fairly common that tracking requests get called before the presence connection has been established.
      // If there isn't a connection ID right now (meaning that the presence has not been established), then we enqueued the request to be
      // proccessed as soon as there is a presence connection.
      if (connectionId)
        return trackUserActionAsVisitor(shopId, connectionId, action);
      else {
        setPendingTrackingRequests([
          ...pendingTrackingRequests,
          {
            shopId: shopId,
            action: action,
          },
        ]);
        // Presence connection has not been established yet. The request will be enqueued for processing as soon as there is a connection available.
        return Promise.resolve();
      }
    } else
      return Promise.reject(new Error("Can't track actions without a shop ID"));
  };

  return (
    <VisitorActionsContext.Provider
      value={{ connectionId, trackVisitorAction }}
    >
      {children}
    </VisitorActionsContext.Provider>
  );
}

// Custom hook that shorhands the context!
export const useVisitorActions = () => useContext(VisitorActionsContext);

import { useState, useEffect, createContext, useContext } from "react";
import firebase from "../firebase/clientApp";
import * as Sentry from "@sentry/nextjs";

const COLLECTION_SHOPS = "shops";
const COLLECTION_ITEMS = "items";

export const CartContext = createContext();

export default function CartContextComponent({ shopId, userId, children }) {
  const [productsInCart, setProductsInCart] = useState([]);
  const [productsMap, setProductsMap] = useState(new Map());

  // Retrieve the cart from the realtime database.
  // Notice that the cart does not contain the Product object per se, only the ID

  function addProductInCart(productId, productData) {
    const newProduct = { id: productId, ...productData };
    setProductsInCart((actualProducts) =>
      Array.from(actualProducts.concat([newProduct]))
    );
  }

  function updateProductInCart(productId, productData) {
    const updatedProduct = { id: productId, ...productData };
    setProductsInCart((actualProducts) => {
      return Array.from(
        actualProducts.map((product) => {
          if (product.id === updatedProduct.id) return updatedProduct;
          else return product;
        })
      );
    });
  }

  function removeProductInCart(productId) {
    removeProductInMap(productId);

    setProductsInCart((actualProducts) =>
      Array.from(actualProducts.filter((product) => product.id !== productId))
    );
  }

  useEffect(() => {
    const userCartDatabaseRef = firebase
      .database()
      .ref(`shops/${shopId}/carts/${userId}/products`)
      .orderByChild("updatedAt");

    userCartDatabaseRef.on(
      "child_added",
      (data) => addProductInCart(data.key, data.val()),
      (error) => Sentry.captureException(error)
    );

    userCartDatabaseRef.on(
      "child_changed",
      (data) => updateProductInCart(data.key, data.val()),
      (error) => Sentry.captureException(error)
    );

    userCartDatabaseRef.on(
      "child_removed",
      (data) => removeProductInCart(data.key),
      (error) => Sentry.captureException(error)
    );

    // Unsubscribe listener on unmount
    return () => {
      setProductsInCart([]);
      userCartDatabaseRef.off();
    };
  }, [shopId]);

  // Using the products IDs retrieved from the cart, look up for the whole products on Firestore

  const updateProductInMap = (product) => {
    const newProductsMap = new Map(productsMap);
    newProductsMap.set(product.id, product);

    setProductsMap(newProductsMap);
  };

  const removeProductInMap = (productId) => {
    const newProductsMap = new Map(productsMap);
    newProductsMap.delete(productId);

    setProductsMap(newProductsMap);
  };

  useEffect(() => {
    const subscriptions = productsInCart.map((productInCart) =>
      firebase
        .firestore()
        .collection(COLLECTION_SHOPS)
        .doc(shopId)
        .collection(COLLECTION_ITEMS)
        .doc(productInCart.id)
        .onSnapshot((productDoc) => {
          try {
            if (productDoc.exists && !productDoc.isEmpty)
              updateProductInMap({
                ...productInCart,
                ...productDoc.data(),
              });
          } catch {
            Sentry.captureException(error);
          }
        })
    );

    return () => subscriptions.forEach((subscription) => subscription());
  }, [shopId, productsInCart]);

  const ProductsAsArray = () => {
    return [...productsMap.values()].sort((productA, productB) => {
      if (productA.id > productB.id) return 1;
      else return -1;
    });
  };

  return (
    <CartContext.Provider
      value={{ products: ProductsAsArray(), productsInCart }}
    >
      {children}
    </CartContext.Provider>
  );
}

// Custom hook that shorhands the context!
export const useCart = () => useContext(CartContext);

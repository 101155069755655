import { useEffect } from "react";
import { Box, VStack, HStack, Center } from "@chakra-ui/react";
import { Display03, Paragraph02 } from "~/components/Texts";
import { useShop } from "~/context/ShopContext";
import DesignLayout from "~/components/designBlocks/DesignLayout";
import { FcIdea } from "react-icons/fc";
import { useVisitorActions } from "~/context/VisitorActionsContext";
import { VISITOR_ACTION_TYPE_VIEW_COLLECTION } from "~/utils/Constants";

export default function ShopCollectionView({ collection }) {
  const { shop, shopDomain } = useShop();
  const { trackVisitorAction } = useVisitorActions();

  useEffect(() => {
    // Report visitor activity
    trackVisitorAction({
      type: VISITOR_ACTION_TYPE_VIEW_COLLECTION,
      collectionId: collection.id,
      rootCollection: true,
    });
  }, [collection.id]);

  if (!collection.layout || collection.layout.blocks.length === 0)
    return <EmptyCollectionPlaceholder theme={shop.theme} />;

  return (
    <Center width="100%" height="100%" px="4">
      <Box width="100%" height="100%" maxW="1400px">
        <DesignLayout
          isEditable={false}
          layoutHash={collection.layout.hash}
          collectionItems={collection.layout.blocks}
          theme={shop.theme}
        />
      </Box>
    </Center>
  );
}

const EmptyCollectionPlaceholder = ({ theme }) => {
  return (
    <VStack h="100%" w="100%" align="center" justify="center" spacing="8">
      <Box w="80%">
        <Display03 textAlign="center" color={theme.colors.text}>
          ¡Llegaste temprano!
        </Display03>
      </Box>
      <Box w="80%">
        <Paragraph02 textAlign="center" color={theme.colors.text}>
          Parece que todavía no tenemos nada para mostrarte.
        </Paragraph02>
      </Box>
      <HStack w="80%" justify="center">
        <FcIdea size={24} />
        <Paragraph02 textAlign="center" color={theme.colors.text}>
          <b>Tip:</b> Si no encontras eso que estas buscando, ¡podemos ayudarte
          a través del chat!
        </Paragraph02>
      </HStack>
    </VStack>
  );
};

import Page from "~/components/Page";
import ShopFrame from "~/components/shop/ShopFrame";
//import { FIREBASE_CLIENT_CREDENTIALS } from "~/utils/Constants";
//import firebase from "firebase/app";
import "firebase/firestore";

// Note: To allow for SSR, we fetch the shop initially server side.
// This shop will be overwritten by the client side on the 'hydratation' stage.
export default function SlugPage({ shop }) {
  return (
    <Page title={`${shop ? shop.displayNames.es : "Tienda"} | Shop Story`}>
      <ShopFrame initialShop={shop} />
    </Page>
  );
}

/*
TODO! IMPORTANT!

SSR is currently disabled due to the lambada function surpassing the 50mb limit.
To fix this, we need to reduce the bundle size.
Unfortunaly, most of the bundle size comes from the Firebase dependency.
This is fixed in Firebase Web SDK 9, but is currently in beta and the migration
from v8 to v9 is a pain in the ass.

Once the v9 of the SDK is implemented and the bundle size decreases, we should be back under
the 50mb SSR limitation.

// Server side fetch the shop object. This allows SSR before sending the page to the client.
export async function getServerSideProps(context) {
  // Initialize Firebase
  // Note: This code checks first if Firebase isn't already initilized.
  // This is necessary because on while developing on Next, the 'server side' will actually run locally and
  // firebase have been initialized already, but not on the real server.
  if (firebase.apps.length === 0) {
    firebase.initializeApp(FIREBASE_CLIENT_CREDENTIALS);
  }

  const shop = await firebase
    .firestore()
    .collection("shops")
    .where("domains", "array-contains", context.query.shopDomain)
    .limit(1)
    .get()
    .then((querySnapshot) => {
      const shop = {
        id: querySnapshot.docs[0].id,
        ...querySnapshot.docs[0].data(),
      };

      return Promise.resolve(shop);
    })
    .catch((error) => Promise.resolve(null));

  // createdAt and updatedAt properties are not serializable
  const { createdAt, updatedAt, ...sanitizedShop } = shop;

  // will be passed to the page component as props
  return {
    props: {
      shop: sanitizedShop,
    },
  };
}
*/

import { useState, useEffect } from "react";
import { useUser } from "~/context/userContext";
import { useShop } from "~/context/ShopContext";
import { useCart } from "~/context/CartContext";
import { useRouter } from "next/router";
import Image from "~/components/Image";
import {
  Box,
  VStack,
  HStack,
  Flex,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Center,
  Heading,
  Wrap,
  WrapItem,
  useTheme,
  SimpleGrid,
} from "@chakra-ui/react";
import AnimatedScalingBox from "~/components/animations/AnimatedScalingBox";
import NumberInput from "~/components/NumberInput";
import { Display03, Label01, Label02, Paragraph02 } from "~/components/Texts";
import { HiShoppingCart } from "react-icons/hi";
import { FiShoppingBag, FiAlertTriangle } from "react-icons/fi";
import { IoIosImages } from "react-icons/io";
import { formatPrice } from "~/utils/Formatters";
import { addProductToCart } from "~/actions/UserActions";
import * as Sentry from "@sentry/nextjs";
import MotionBox from "~/components/MotionBox";
import { useVisitorActions } from "~/context/VisitorActionsContext";
import {
  VISITOR_ACTION_TYPE_VIEW_PRODUCT,
  DEFAULT_PRODUCT_STYLE,
} from "~/utils/Constants";
import ThemeableButton from "~/components/ThemeableButton";
import tinycolor from "tinycolor2";
import PageHead from "~/components/PageHead";
import AnimatedEntryOnViewport from "~/components/animations/AnimatedEntryOnViewport";
import { useAnimation } from "framer-motion";
import TagsViewer from "~/components/TagsViewer";
import Link from "next/link";

const PRODUCT_DESIGN_COMPACT = "compact";
const PRODUCT_DESIGN_EXTENDED = "extended";
const PRODUCT_DESIGN_COLUMNS = "columns";
const PRODUCT_DESIGN_VISUAL = "visual";

export default function ShopProductView({ product, shopItemSlug }) {
  const { shop } = useShop();
  const { trackVisitorAction } = useVisitorActions();
  const productDesign = product?.style?.design || DEFAULT_PRODUCT_STYLE;

  useEffect(() => {
    // Setup Sentry's context
    Sentry.setContext("product", product);
    Sentry.setTag("productId", product.id);

    // Report visitor activity
    trackVisitorAction({
      type: VISITOR_ACTION_TYPE_VIEW_PRODUCT,
      productId: product.id,
    });
  }, [product]);

  // ** Build the layout based on the product's design style **
  const ProductContent = () => {
    switch (productDesign) {
      case PRODUCT_DESIGN_COLUMNS:
      case PRODUCT_DESIGN_VISUAL:
        return (
          <VStack
            w="100%"
            maxW="1000px"
            px={{ mobile: "4", desktop: "8" }}
            pt={{
              mobile: "8",
              desktop: "12",
            }}
            pb="8"
            spacing="12"
          >
            <AnimatedEntryOnViewport>
              <ProductGeneralInfo product={product} theme={shop.theme} />
            </AnimatedEntryOnViewport>
            <AnimatedEntryOnViewport>
              <ProductImages
                product={product}
                theme={shop.theme}
                style={productDesign}
              />
            </AnimatedEntryOnViewport>
          </VStack>
        );
      case PRODUCT_DESIGN_EXTENDED:
      case PRODUCT_DESIGN_COMPACT:
      default:
        return (
          <Flex
            pt={{
              mobile: "8",
              desktop: productDesign === PRODUCT_DESIGN_COMPACT ? "32" : "8",
            }}
            direction={{
              mobile: "column",
              desktop:
                productDesign === PRODUCT_DESIGN_COMPACT ? "row" : "column",
            }}
            w="100%"
            maxW={productDesign === "extended" ? "1000px" : undefined}
            align="flex-start"
            pb="8"
          >
            <Box flex="1" w="100%" h="100%">
              <AnimatedEntryOnViewport>
                <ProductImages
                  product={product}
                  theme={shop.theme}
                  style={productDesign}
                />
              </AnimatedEntryOnViewport>
            </Box>
            <Box w="12" h="4" />
            <Box flex="1">
              <AnimatedEntryOnViewport>
                <ProductGeneralInfo product={product} theme={shop.theme} />
              </AnimatedEntryOnViewport>
            </Box>
          </Flex>
        );
    }
  };

  return (
    <>
      <PageHead title={`${product.name.es} | ${shop.displayNames.es}`} />
      <VStack w="100%" h="100%" spacing="0">
        <VStack w="100%" h="100%" align="flex-start" spacing="0">
          {product.paused && <PausedProductBanner />}
          {product.coverImage && (
            <Box
              w="100%"
              h={{ mobile: "200px", desktop: "400px" }}
              backgroundColor={shop.theme.colors.background.strong}
            >
              <Image
                src={product.coverImage.src}
                scaleType={product.coverImage.scaleType}
              />
            </Box>
          )}
          <Center w="100%" px="4">
            <Center w="100%" maxW="1400px" h="100%">
              <ProductContent />
            </Center>
          </Center>
        </VStack>
      </VStack>
    </>
  );
}

const ProductGeneralInfo = ({ product, theme }) => {
  return (
    <VStack flex="1" align="flex-start" spacing="8">
      <TagsViewer tags={product.tags} theme={theme} />
      <VStack w="100%" align="flex-start">
        <Heading
          as="h1"
          size="4xl"
          textStyle="display02"
          color={theme.colors.text}
          fontFamily={theme.fonts.headline}
        >
          {product.name.es}
        </Heading>
        <HStack h="100%" align="flex-end">
          <VStack h="100%">
            <Label01 color={theme.colors.text}>
              <b>$</b>
            </Label01>
            <Box flex="1" />
          </VStack>
          <Display03 color={theme.colors.text}>{`${formatPrice(
            product.price.ars
          )}`}</Display03>
          <VStack h="100%">
            <Label02 color="gray.500">
              <b>+ envío</b>
            </Label02>
          </VStack>
        </HStack>
        <Paragraph02 color={theme.colors.text}>{`${product.availableStock} ${
          product.availableStock > 1
            ? "unidades disponibles"
            : "unidad disponible"
        }`}</Paragraph02>
      </VStack>
      <Box w="100%">
        <BuyingOptions product={product} theme={theme} />
      </Box>
      <Paragraph02 color={theme.colors.text} textAlign="justify">
        {product.summary.es}
      </Paragraph02>
    </VStack>
  );
};

const ProductImages = ({ product, theme, style }) => {
  const [selectedImage, setSelectedImage] = useState(product.images[0]);

  const ImageCover = ({ image }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const imageAnimationControls = useAnimation();

    useEffect(() => {
      // Triger a 'fade in' when the selected image  changes
      imageAnimationControls.start({
        opacity: [0, 1],
        transition: { duration: 0.3 },
      });
    }, [image]);

    return (
      <Box
        position="relative"
        width="100%"
        height={{ mobile: "300px", desktop: "400px" }}
        borderRadius={theme.radius}
        borderColor={theme.colors.background.lite}
        borderWidth="2px"
        backgroundColor={theme.colors.background.lite}
        cursor="pointer"
        overflow="hidden"
        onClick={() => onOpen()}
      >
        {product.images && (
          <>
            <MotionBox w="100%" h="100%" animate={imageAnimationControls}>
              <Image src={image?.src} scaleType={image?.scaleType} />
            </MotionBox>
            <FullscreenImageModal
              modalImage={image}
              onClose={onClose}
              isOpen={isOpen}
            />
          </>
        )}
        {!image && (
          <VStack
            h="100%"
            w="100%"
            justify="center"
            spacing="4"
            color={theme.colors.text}
          >
            <IoIosImages size="64px" />
            <Paragraph02>No hay imágenes del producto</Paragraph02>
          </VStack>
        )}
      </Box>
    );
  };

  const ImageThumbnails = ({ images }) => {
    return (
      <HStack w="100%" overflowX="auto">
        {images?.length > 1 &&
          images.map((image, index) => (
            <AnimatedScalingBox key={index}>
              <Center
                width={{ mobile: "100px", desktop: "125px" }}
                height={{ mobile: "100px", desktop: "125px" }}
                position="relative"
                borderRadius={theme.radius}
                borderColor={theme.colors.background.lite}
                borderWidth="2px"
                overflow="hidden"
                cursor="pointer"
                onMouseEnter={() => setSelectedImage(image)}
                onClick={() => setSelectedImage(image)}
                backgroundColor={theme.colors.background.lite}
              >
                <Image src={image.src} scaleType="cover" />
              </Center>
            </AnimatedScalingBox>
          ))}
      </HStack>
    );
  };

  const FullscreenImageModal = ({ modalImage, onClose, isOpen }) => {
    if (!isOpen) return null;

    return (
      <Modal
        isCentered
        useInert={false}
        onClose={onClose}
        motionPreset="scale"
        size="6xl"
        isOpen={isOpen}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent
          backgroundColor={theme.colors.background.superLite}
          borderRadius={theme.radius}
          color={theme.colors.text}
        >
          <ModalCloseButton />
          <ModalBody>
            <Center w="100%" h="100%">
              <Box boxSize="3xl" position="relative">
                <Image
                  layout="fill"
                  src={modalImage?.src}
                  objectFit="contain"
                />
              </Box>
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  switch (style) {
    case PRODUCT_DESIGN_COLUMNS:
      return (
        <SimpleGrid w="100%" spacing="4" columns={{ base: 2, lg: 3 }}>
          {product.images.map((image, index) => {
            return (
              <Box key={`image_cover_#${index}`}>
                <ImageCover image={image} />
                <FullscreenImageModal modalImage={image} />
              </Box>
            );
          })}
        </SimpleGrid>
      );
    case PRODUCT_DESIGN_VISUAL:
      return (
        <VStack w="100%" spacing="4">
          {product.images.map((image, index) => (
            <Box key={`image_cover_#${index}`} w="100%">
              <ImageCover image={image} />
            </Box>
          ))}
        </VStack>
      );
    default:
      return (
        <VStack w="100%">
          <ImageCover image={selectedImage} />
          <ImageThumbnails images={product.images} />
        </VStack>
      );
  }
};

const BuyingOptions = ({ product, theme }) => {
  const { user } = useUser();
  const { shop } = useShop();
  const { products: cartProducts } = useCart();
  const toast = useToast();
  const router = useRouter();
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  useEffect(() => {
    if (cartProducts) {
      cartProducts.forEach((cartProduct) => {
        if (product.id === cartProduct.id)
          setSelectedQuantity(cartProduct.quantity);
      });
    }
  }, [cartProducts]);

  const productCartData = {
    quantity: selectedQuantity || 1,
  };

  const successToast = () =>
    toast({
      position: "top",
      title: `Producto agregado`,
      description: `Agregaste ${product.name.es} a tu carrito`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });

  const errorToast = () =>
    toast({
      position: "top",
      title: `No se pudo agregar el producto`,
      description: `El producto ${product.name.es} no pudo agregarse a tu carrito`,
      status: "error",
      duration: 3000,
      isClosable: true,
    });

  const addProduct = () => {
    addProductToCart(shop.id, product.id, productCartData, user.uid)
      .then(() => successToast(product))
      .catch((error) => {
        Sentry.captureException(error);
        errorToast();
      });
  };

  return (
    <Wrap spacing="4" w="100%" wrap="wrap">
      <WrapItem>
        <Link
          href={`/${
            router.query.shopDomain ? router.query.shopDomain + "/checkout" : ""
          }`}
        >
          <ThemeableButton
            theme={theme}
            leftIcon={<FiShoppingBag />}
            onClick={() => addProduct()}
            isDisabled={product.paused || product.availableStock <= 0}
            backgroundColor={theme.colors.action.primary}
            color={tinycolor
              .mostReadable(theme.colors.action.primary, [theme.colors.text], {
                includeFallbackColors: true,
              })
              .toHexString()}
          >
            Comprar
          </ThemeableButton>
        </Link>
      </WrapItem>
      <WrapItem>
        <ThemeableButton
          theme={theme}
          leftIcon={<HiShoppingCart />}
          colorScheme="brandLite"
          onClick={() => addProduct()}
          isDisabled={product.paused || product.availableStock <= 0}
          backgroundColor={theme.colors.action.secondary}
          color={tinycolor
            .mostReadable(theme.colors.action.secondary, [theme.colors.text], {
              includeFallbackColors: true,
            })
            .toHexString()}
        >
          Agregar
        </ThemeableButton>
      </WrapItem>
      <WrapItem maxW="200px">
        <NumberInput
          value={selectedQuantity}
          minValue={1}
          maxValue={product.availableStock}
          onChange={(value) => setSelectedQuantity(value)}
          theme={theme}
        />
      </WrapItem>
    </Wrap>
  );
};

const PausedProductBanner = () => {
  const chakraTheme = useTheme();

  return (
    <HStack
      px="4"
      py="2"
      w="100%"
      justify="center"
      backgroundColor="feel.warning"
    >
      <FiAlertTriangle color={chakraTheme.colors.light.brand.strong} />
      <Paragraph02 color="light.brand.strong" textAlign="center">
        <b>Este producto no se encuentra disponible en este momento</b>
      </Paragraph02>
    </HStack>
  );
};

import PropTypes from "prop-types";
import {
  Wrap,
  WrapItem,
  useColorModeValue,
  HStack,
  Button,
} from "@chakra-ui/react";
import tinyColor from "tinycolor2";
import { FiHash } from "react-icons/fi";
import { Paragraph04 } from "~/components/Texts";
import Link from "next/link";
import { useRouter } from "next/router";

TagsViewer.propTypes = {
  tags: PropTypes.array.isRequired,
  theme: PropTypes.object,
  size: PropTypes.string,
};
TagsViewer.defaultProps = {
  theme: null,
  size: "sm",
};

export default function TagsViewer({ tags, theme, size }) {
  const router = useRouter();

  return (
    <Wrap w="100%" h="100%">
      {tags
        .filter((tag) => tag) // Removed any potential 'blank' tags
        .map((tag, index) => {
          const COLOR_ACTION_PRIMARY = theme
            ? tinyColor(theme.colors.action.primary)
            : null;
          const COLOR_BACKGROUND = theme
            ? tinyColor(theme.colors.action.secondary)
            : null;

          return (
            <WrapItem key={`tag_#${index}_@${tag}`}>
              <Link
                href={{
                  pathname: "/[shopDomain]/search",
                  query: { shopDomain: router.query.shopDomain, tags: [tag] },
                }}
              >
                <Button
                  key={`tag_button_#${index}_@${tag}`}
                  size={size}
                  variant="unstyled"
                  borderRadius={theme?.radius || "brand"}
                  overflow="hidden"
                  backgroundColor={
                    theme
                      ? COLOR_BACKGROUND.toHexString()
                      : useColorModeValue("light.brand.lite", "dark.brand.lite")
                  }
                  color={
                    theme
                      ? tinyColor
                          .mostReadable(
                            theme.colors.action.secondary,
                            [theme.colors.text],
                            {
                              includeFallbackColors: true,
                            }
                          )
                          .toHexString()
                      : useColorModeValue(
                          "light.background.superLite",
                          "dark.background.superLite"
                        )
                  }
                  _focus={{
                    boxShadow: `0 0 0 2px ${
                      theme
                        ? COLOR_ACTION_PRIMARY.toRgbString()
                        : "action.primary"
                    }`,
                  }}
                  _hover={{
                    bg: theme
                      ? COLOR_BACKGROUND.isLight()
                        ? COLOR_BACKGROUND.darken().toHexString()
                        : COLOR_BACKGROUND.lighten().toHexString()
                      : useColorModeValue(
                          "light.brand.strong",
                          "dark.brand.strong"
                        ),
                  }}
                  _active={{
                    bg: theme
                      ? COLOR_BACKGROUND.darken().toHexString()
                      : useColorModeValue(
                          "light.brand.strong",
                          "dark.brand.strong"
                        ),
                    transform: "scale(0.98)",
                    borderColor: theme
                      ? theme.colors.action.primary
                      : "action.primary",
                  }}
                >
                  <HStack
                    h="100%"
                    w="100%"
                    spacing="1"
                    px={size !== "xs" ? "3" : "2"}
                  >
                    <FiHash />
                    <Paragraph04 textTransform="uppercase">{tag}</Paragraph04>
                  </HStack>
                </Button>
              </Link>
            </WrapItem>
          );
        })}
    </Wrap>
  );
}
